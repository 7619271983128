import { ModernaTheme, Theme } from "@modernatx/ui-kit-react";

const accent00 = "#ADDCF2";
const accent01 = "#F2FAFD";
const accent02 = "#B8CAE1";
const accent03 = "#7D9AC4";
const accent04 = "#436AA8";
const accent05 = "#33507f";
const accent06 = "#294168";
const white = "#ffffff";

const buttonStyles: Partial<Theme["colorsDark"]> = {
  buttonActiveBackgroundColorPrimary: accent06,
  buttonActiveBorderColorPrimary: accent06,
  buttonBackgroundColorPrimary: accent04,
  buttonBorderColorPrimary: accent04,
  buttonFocusBorderColorPrimary: accent04,
  buttonHoverBackgroundColorPrimary: accent05,
  buttonHoverBorderColorPrimary: accent05,
  buttonHoverBorderColorSecondary: accent00
};

const iconButtonStyles: Partial<Theme["colorsDark"]> = {
  iconButtonActiveBackgroundColor: accent03,
  iconButtonBackgroundColor: accent01,
  iconButtonColorPrimary: accent04,
  iconButtonHoverBackgroundColor: accent02,
  iconButtonHoverBorderColor: accent02
};

export const HcpTheme: Theme = {
  ...ModernaTheme,
  colorsLight: {
    ...ModernaTheme.colorsLight,
    ...buttonStyles,
    ...iconButtonStyles,
    accent01: accent04,
    accent02: accent00,
    cta01: accent04,
    link01: accent04,
    notification03: "#ECF7F6",
    text04: "#F000E7",
    tableBodyEvenBackgroundColor: white,
    tableHeaderBackgroundColor: accent04
  },
  colorsDark: {
    ...ModernaTheme.colorsDark,
    ...buttonStyles,
    accent01: accent04,
    accent02: accent00,
    cta01: white,
    link01: white
  }
};
